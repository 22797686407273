window.onClickPasswordEye = function(target) {
    $(target).toggleClass("ti-eye ti-eye-off");

    let input;
    if ($(target).parent().prev("input")[0]) {
        input = $(target).parent().prev("input");
    } else {
        input = $(target).parent().prev(".field_with_errors").children("input");
    }

    if (input.attr("type") == "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
}

window.setCookieRememberMe = function(target) {
    const el = document.getElementById("user_remember_me");
    const age = 60 * 60 * 24 * 30* 365;
    document.cookie = "remember_me=" + el.checked + "; max-age=" + age;
}